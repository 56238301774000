<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_cluster">Filter By Cluster</label>
            <v-select
              id="filter_cluster"
              v-model="selected.cluster"
              :options="list.clusters"
              label="cluster_name"
              placeholder="-- Please Select Cluster --"
            >
              <template #option="{ cluster_name }">
                <span>{{ cluster_name }}</span>
              </template>
              <template #no-options="">
                No Available Clusters
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_area">Filter By Area</label>
            <v-select
              id="filter_area"
              v-model="selected.area"
              :options="list.areas"
              label="area_name"
              placeholder="-- Please Select Area --"
            >
              <template #option="{ area_name }">
                <span>{{ area_name }}</span>
              </template>
              <template #no-options="">
                No Available Areas
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <label>Filter By Crop</label>
            <v-select
              id="crop"
              v-model="selected.crop"
              :options="list.crops"
              label="crop_name"
              placeholder="-- Please Select Crop --"
            >
              <template #option="{ crop_name }">
                <span>{{ crop_name }}</span>
              </template>
              <template #no-options="">
                No Available Crops
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            :disabled="tableSettings.totalRows < 1"
            class="mr-1"
            variant="primary"
            @click="state.selected ? onUnSelectAll() : onSelectAll()"
          >
            {{ state.selected ? 'Uncheck Current' : 'Select Current' }}
          </b-button>

          <b-button
            :disabled="selected.big_land_owners.length < 1"
            variant="secondary"
            @click="onShowAssignModal"
          >
            Assign Data
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(lead_agent)="data">
          <div class="text-nowrap">
            <span>{{ `${data.item.lead_agent?.first_name || ''} ${data.item.lead_agent?.last_name || ''}` }}</span>
          </div>
        </template>

        <template #cell(selected)="row">
          <div class="text-nowrap">
            <div class="form-group">
              <b-form-checkbox
                v-model="selected.big_land_owners"
                :disabled="row.item.lead_agent !== null"
                :value="row.item.id"
              />
            </div>
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="danger"
              :disabled="row.item.lead_agent === null"
              @click="onConfirmDissociateData(row.item)"
            >
              Dissociate
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-assign-data"
      scrollable
      no-close-on-backdrop
      title="Assign Big Land Owner"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div class="col-12 mb-5">
            <ValidationProvider
              #default="{ errors }"
              name="agent"
              vid="agent"
              rules="required"
            >
              <b-form-group>
                <label for="agents">Agent</label>
                <v-select
                  id="agents"
                  v-model="selected.agent"
                  :options="list.agents"
                  :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                  :disabled="state.busy"
                  label="full_name"
                >
                  <template #option="{ full_name, email }">
                    <strong>{{ full_name }}</strong><br>
                    <span>{{ email }}</span>
                  </template>
                  <template #no-options="">
                    No Available Agents
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AdminBigLandOwnerService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorCampaignData',

  middleware: ['auth', 'admin'],

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Big Land Owners'
    }
  },

  data () {
    return {
      state: {
        busy: false,
        selected: false
      },
      filter: {
        cluster: 'all',
        area: 'all',
        crop: 'all'
      },
      list: {
        clusters: [],
        areas: [],
        crops: []
      },
      selected: {
        agent: null,
        big_land_owners: [],
        cluster: null,
        area: null,
        crop: null
      },
      taggingData: {
        agent: null,
        selected: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'selected' },
          { key: 'actions' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'customer_type' },
          { key: 'mobile_number' },
          { key: 'lead_agent' },
          { key: 'cluster.cluster_name', label: 'cluster' },
          { key: 'area.area_name', label: 'area' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },

  watch: {
    'selected.cluster' (value) {
      this.filter.cluster = value?.id || 'all'
      this.getAreas(value?.id || null)
      this.onFilterChanged()
    },

    'selected.area' (value) {
      this.filter.area = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.crop' (value) {
      this.filter.crop = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.agent' (value) {
      this.taggingData.agent = value?.id || null
    },

    'selected.big_land_owners' (value) {
      this.taggingData.selected = value

      if (value.length > 0) {
        this.state.selected = true
      } else {
        this.state.selected = false
      }
    }
  },

  mounted () {
    core.index()
    this.getClusters()
    this.getCrops()
    this.getLeadAgents()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminBigLandOwnerService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_cluster: this.filter.cluster,
            filter_area: this.filter.area,
            filter_crop: this.filter.crop
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getClusters () {
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      if (cluster) {
        this.list.areas = []
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data
        })
      } else {
        this.list.areas = []
      }
    },

    async getCrops () {
      await SharedListService.getCrops().then(({ data }) => {
        this.list.crops = data
      })
    },

    async getLeadAgents () {
      await SharedListService.getLeadAgents().then(({ data }) => {
        this.list.agents = data
      })
    },

    onShowAssignModal () {
      this.selected.agent = null
      this.$bvModal.show('modal-assign-data')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Are you sure you want to assign this data to this current lead agent?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    onConfirmDissociateData (current) {
      if (current.lead_agent === null) {
        return
      }
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Are you sure you want to dissociate data from current lead agent?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.onFormPutSubmit(current)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminBigLandOwnerService.post(this.taggingData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-assign-data')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.$refs.table.refresh()
              this.state.selected = false
              this.selected.big_land_owners = []
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    async onFormPutSubmit (payload) {
      return new Promise(resolve => {
        this.state.busy = true
        AdminBigLandOwnerService.put(payload).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-assign-data')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onSelectAll () {
      const filteredItem = this.$refs.table.localItems?.filter(item => {
        return item.lead_agent === null
      })

      if (filteredItem.length > 0) {
        this.state.selected = true
      }

      this.selected.big_land_owners = filteredItem.map(item => {
        return item.lead_agent === null ? item.id : null
      })
    },

    onUnSelectAll () {
      this.state.selected = false
      this.selected.big_land_owners = []
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
